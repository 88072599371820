<template>
    <div class="row mt-3" style="cursor: pointer;" @click="goToProfile">
        <div class="col-12 ps-4 pe-4">
            <div class="row">
                <div class="col-2 ps-0 pe-0 form-rounded-left" :style="expert.subscription_level >= expertProLevel ? 'border: 3px solid #7FAA2D; border-style: solid none solid solid;' : ''">
                    <div style="max-height: 168px; overflow: hidden;">
                        <img v-if="expertImage" :src="expertImage.url" class="mw-100 form-rounded-left" alt="..." />
                    </div>
                </div>

                <div class="col-3 bg-white py-3" :style="expert.subscription_level >= expertProLevel ? 'border: 3px solid #7FAA2D; border-style: solid none solid none;' : ''">
                    <h5 class="card-title mp-rbk">{{ expertName }}</h5>
                    <span class="d-block mb-3 mp-qks mp-font-weight-medium">{{ expert.desc_brief }}</span>
                </div>

                <div class="col-3 bg-white py-3" :style="expert.subscription_level >= expertProLevel ? 'border: 3px solid #7FAA2D; border-style: solid none solid none;' : ''">
                    <span class="d-block mp-rbk mp-font-weight-medium mp-black-expert-area-of-expertise">FACHGEBIETE</span>
                    <ul class="ps-3">
                        <li class="mp-qks mp-black-expert-area-of-expertise" v-for="a in expertAreas" :key="`${keyPrefix}_${expert.id}_a_${a.id}`">
                            {{ a.title }} {{ a.in_training ? '(i.A.)' : ''}}
                        </li>
                    </ul>
                </div>
                <!-- insurances -->

                <div class="col-2 bg-white py-3" :style="expert.subscription_level >= expertProLevel ? 'border: 3px solid #7FAA2D; border-style: solid none solid none;' : ''">
                    <template v-if="expertInsurances.length > 0">
                    <span class="d-block mp-rbk mp-font-weight-medium mp-black-expert-area-of-expertise">KASSEN</span>
                    <ul class="ps-3">
                        <li class="mp-qks mp-black-expert-area-of-expertise" v-for="a in expertInsurances" :key="`${keyPrefix}_${expert.id}_a_${a.id}`">
                            {{ a.title }}
                        </li>
                    </ul>
                    </template>
                </div>

                <div class="col-2 ps-0 pe-0 d-flex me-0" :style="expert.subscription_level >= expertProLevel ? 'border: 3px solid #7FAA2D; border-style: solid solid solid none;' : ''">
                    <div class="d-block w-100 bg-white">
                        <div class="row pe-2 h-100">
                            <div class="col-12 d-flex al justify-content-end pt-2">
                                <!-- like button -->
                                <div v-if="isAuth" class="d-flex justify-content-center align-self-top expert-list-like mp-black-expert-like-btn form-shadow">
                                    <i class="align-self-center fas fa-heart mp-green" v-if="isFavorite" style="cursor: pointer" @click="toggleFavorite"></i>
                                    <i class="align-self-center far fa-heart" v-else style="cursor: pointer" @click="toggleFavorite"></i>
                                </div>
                                <!-- /like button -->
                            </div>
                            <div class="col-12 mp-font-weight-medium d-flex flex-column justify-content-end text-end pb-2">
                                <span v-if="expertCourseCount" class="d-block mp-black-expert-area-of-expertise" >{{ expertCourseCount }} Angebote</span>
                                <span v-if="expertReviewCount" class="d-block mp-black-expert-area-of-expertise" >{{ expertReviewCount }} Erfahrungsberichte</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>




<script>
    import ExpertCard from "./ExpertCard";

    export default {
        name: "ExpertCardHorizontal",
        extends: ExpertCard,

        data() {
            return {
                keyPrefix: 'expCardH'
            };
        },
    }
</script>




<style scoped>

</style>