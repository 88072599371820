<template>
    <div>
        <!-- result -->
        <div class="row">
            <template v-if="filtered.length > 0">
                <div class="col-12">
                    <!-- expert cards -->
                    <div class="container">
                        <template v-if="filter.listStyleTiles">
                            <!-- tiles -->
                            <div class="row">
                                <CourseCard :course="e" :smaller="true" v-for="e in filtered" :key="`${keyPrefix}_${e.id}_c_c`" />
                            </div>
                        </template>
                        <template v-else>
                            <!-- rows -->
                            <CourseCardHorizontal :course="e" v-for="e in filtered" :key="`${keyPrefix}_${e.id}_c_c`" />
                        </template>
                        <div class="row" v-if="filter.resultLimit">
                            <div class="col-12 pt-5 text-center">
                                <span href="#" class="mt-5 btn btn-mp-green-experts-load-more mp-qks mp-font-weight-medium" @click="goToOverview">Mehr...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <!-- no results -->
                <div class="col-12 text-center mt-5 mb-5" style="font-weight: bold; font-size: 1.4rem" >
                    <span v-if="filter.searchTerm">Deine Suche nach '{{filter.searchTerm}}' hat leider keine Ergebnisse geliefert.</span>
                    <span v-else>Deine Suche hat leider keine Ergebnisse geliefert.</span>
                </div>
            </template>
        </div>
    </div>
</template>




<script>
    import {mixinBasics} from "../mixins/basics";
    import Constants from "../constants";
    import Experts from "./Experts.vue";
    import CourseCard from "./CourseCard.vue";
    import CourseCardHorizontal from "./CourseCardHorizontal.vue";


    export default {
            name: "Courses",

            extends: Experts,

            components: {
                CourseCardHorizontal,
                CourseCard,
            },

            mixins: [
                mixinBasics
            ],

            props: {
            },

            data() {
                return {
                    keyPrefix: 'courses',
                    isLoading: false,
                };
            },

            computed: {
                filtered() {
                    let courses = this._courses;
                    if (this.filter.areaId) {
                        courses = courses.filter(c => c.area_id === this.filter.areaId);
                    }
                    if (this.filter.categoryId) {
                        courses = courses.filter(c => c.category_id === this.filter.categoryId);
                    }
                    if (this.filter.courseTypeId) {
                        let courses = this._courses.filter(c => c.course_type_id === this.filter.courseTypeId);
                        courses = courses.filter(e => courses.find(c => c.user_id === e.user_id));
                    }
                    if (this.filter.searchTerm && this.filter.searchTerm.length >= 3) {
                        let sTerm = this.filter.searchTerm.toLowerCase();
                        courses = courses.filter(e =>
                            e.tags.find(t => {
                                return t.title.toLowerCase().includes(sTerm) || e.areas.find(a => a.title.toLowerCase().includes(sTerm))
                            })
                        );
                    }

                    let res =  this.filter.sortBy === Constants.filterSortBy.DISTANCE ?
                            // they r always pre-sorted (BE) by distance
                            courses :
                            // sort by name
                            courses.sort((a,b) => {
                                let nameA = a.title.toUpperCase();
                                let nameB = b.title.toUpperCase();
                                return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
                            })

                    return this.filter.resultLimit ? res.filter((e, idx) => idx < this.filter.resultLimit) : res;
                },

                selectedCategory() {
                    return this.filter.categoryId ? this._categories.find(c => c.id === this.filter.categoryId) : undefined;
                },

                searchOptions() {
                    if (!this.filter.searchTerm) {
                        return this._tags;
                    }
                    let search = this.filter.searchTerm.toLowerCase();
                    return this._tags.filter(t => t.title.toLowerCase().indexOf(search) >= 0);
                },
            },

            methods: {
                debugInfo() {
                    console.log('Courses:');
                    console.log(this.filter);
                    console.log('ResultLimit :' + this.filter.resultLimit);
                },

                goToOverview() {
                    this.$router.push(`/kurse`);
                }
            },
        }
</script>




<style scoped>

</style>