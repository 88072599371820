<template>
    <!-- tag cloud -->
    <div class="container-fluid bg-tag-cloud position-relative" v-if="isStoreInitialized">
        <div class="row position-relative">
            <div class="col-12">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-12 text-white text-center px-5">
                            <span v-for="t in tags" class="tag-cloud-link" style="cursor: pointer;" @click="doSearch(t.title)" :key="`${keyPrefix}_${t.id}`">{{ t.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /tag cloud -->
</template>




<script>
    import {mixinBasics} from "../mixins/basics";
    import eventBus from "../eventbus";
    import Const from "../constants";


    export default {
        name: "TagCloud",

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'tagC',
                tags: []
            };
        },

        methods: {
            doSearch(e) {
                eventBus.$emit(Const.events.DO_SEARCH, e);
            },

            getRndTags() {
                return this.getRandomIntArray(0, this._tagsInUse.length-1, 10).map(e => this._tagsInUse[e]);
            },
        },

        created() {
            this.tags = this.getRndTags();
        }
    }
</script>




<style scoped>

</style>