<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="doClose"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold" style="font-size: 1.4rem">{{ title }}</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0 text-center" style="font-size: 1.2rem;">
                                <span v-html="msg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 p-0 mt-5">
                    <div class="container px-0">
                        <div class="row">
                            <div class="col-12">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="doClose">
                                    Schliessen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import BaseModal from "./BaseModal";
    import { mixinBasics } from "../../mixins/basics";


    export default {
        name: "Info",

        extends: BaseModal,

        mixins: [
            mixinBasics
        ],

        props: {
            title: {
                type: String,
                required: true
            },
            msg: {
                type: String,
                required: true
            },
        },


        data() {
            return {
                modalId: 'info-modal',
                modal: undefined,
            }
        },

        methods: {
            doClose() {
                this.$emit('close');
            },
        }
    }
</script>



<style scoped>

</style>
