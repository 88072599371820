<template>
    <div class="col-12 col-md-6 col-lg-3 mt-4">
        <div class="card h-100 form-rounded-left form-rounded-right" :style="expert.subscription_level >= expertProLevel ? 'border: 3px solid #7FAA2D; cursor: pointer; overflow: hidden;' : 'cursor: pointer;'" @click="goToProfile">
            <div
                :class="expert.subscription_level >= expertProLevel ? 'card-img-top' : 'card-img-top form-rounded-top'"
                :style="imgStyle"
            />
            <div class="card-body d-flex flex-column">
                <h5 class="card-title mp-rbk">{{ expertName }}</h5>
                <span class="d-block mb-3 mp-qks mp-font-weight-medium truncate" v-html="expertBriefDesc"></span>
                <span class="d-block mp-rbk mp-font-weight-medium mp-black-expert-area-of-expertise">FACHGEBIETE</span>
                <!-- areas of expertise -->
                <ul class="ps-3">
                    <li class="mp-qks mp-black-expert-area-of-expertise" v-for="a in expertAreas" :key="`${keyPrefix}_${expert.id}_a_${a.id}`">
                        {{ a.title }} {{ a.in_training ? '(i.A.)' : ''}}
                    </li>
                </ul>
                <!-- insurances -->
                <template v-if="expertInsurances.length > 0">
                    <span class="d-block mp-rbk mp-font-weight-medium mp-black-expert-area-of-expertise">KASSEN</span>
                    <ul class="ps-3">
                        <li class="mp-qks mp-black-expert-area-of-expertise" v-for="a in expertInsurances" :key="`${keyPrefix}_${expert.id}_a_${a.id}`">
                            {{ a.title }}
                        </li>
                    </ul>
                </template>
                <!-- courses & reviews -->
                <div class="mt-auto">
                    <span v-if="expertCourseCount" class="expert-listing-num-field-reports" style="margin-left: 6px;">{{ expertCourseCount }} Angebote</span><br>
                    <span v-if="expertReviewCount" class="expert-listing-num-field-reports pt-1" style="margin-left: 6px;">{{ expertReviewCount }} Erfahrungsberichte</span>
                </div>
            </div>
            <!-- like button -->
            <div v-if="isAuth" class="expert-like d-flex flex-column justify-content-center text-center mp-black-expert-like-btn form-shadow">
                <i class="fas fa-heart mp-green" v-if="isFavorite" style="cursor: pointer" @click.stop="toggleFavorite"></i>
                <i class="far fa-heart" v-else style="cursor: pointer" @click.stop="toggleFavorite"></i>
            </div>
            <!-- /like button -->
        </div>
    </div>
</template>




<script>
    import {mixinBasics} from "../mixins/basics";
    import {apiCall} from "../helpers";
    import Configs from "../configs";
    import {mapActions} from "vuex";

    const api = Configs.restApi.endpoints.map;
    const expertProLevel = 3;
    const expertAreasLimit = 2;
    const expertInsurancesLimit = 2;

    export default {
        name: "ExpertCard",

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'expCard',
                expertProLevel
            };
        },

        props: {
            id: {
                type: Number,
                required: true
            },

            isFavorite: {
                required: false,
                default: false
            },
        },

        computed: {
            expert() {
                return this._experts.find(e => e.id === this.id);
            },

            expertName() {
                let title = this.expert.title ?? '';
                let titleTrailing = this.expert.title_trailing ? `, ${this.expert.title_trailing}` : '';
                return `${title} ${this.expert.firstname} ${this.expert.lastname}${titleTrailing}`;
            },

            expertNameMeta() {
                return `${this.expert.firstname}-${this.expert.lastname}`;
            },

            expertImage() {
                return this.expert.media[0];
            },

            expertAreas() {
                let expAreas = this.expert.areas.filter(a => a.verified);
                return expAreas.length > expertAreasLimit ? expAreas.slice(0, expertAreasLimit).concat({id: -1, title: 'mehr...'}) : expAreas;
            },

            expertBriefDesc() {
                return this.expert.desc_brief.trim().length > 0 ? this.expert.desc_brief : '&nbsp;';
            },

            expertCourseCount() {
                return this.expert.courses.length;
            },

            expertReviewCount() {
                return this.expert.reviews.length;
            },

            expertInsurances() {
                return this.expert.insurances.length > expertInsurancesLimit ? this.expert.insurances.slice(0, expertInsurancesLimit).concat({id: -1, title: 'mehr...'}) : this.expert.insurances;
            },

            imgStyle() {
                let bg = this.expertImage ? `background: url('${this.expertImage.url}')` : 'background-color: grey';
                return `
                    height: 250px;
                    ${bg};
                    max-height: 250px;
                    height: 250px;
                    overflow: hidden;
                    background-position: top center;
                    background-size: cover;
                    background-repeat: no-repeat;
                `;
            }
        },

        methods: {
            ...mapActions('main', {
                _loadUserProfile: 'loadUserProfile',
            }),

            async toggleFavorite() {
                let favs = this.isFavorite ? this.favRemove() : this.favAdd();
                await this.doUserFavoritesSave(favs);
            },

            favAdd() {
                let res = this.profile.favoriteExperts.map(e => e.user_id);
                res.push(this.expert.user_id);
                return res;
            },

            favRemove() {
                return this.profile.favoriteExperts.filter(e => e.user_id !== this.expert.user_id).map(e => e.user_id);
            },

            async doUserFavoritesSave(favs) {
                let payload = {favorite_experts: favs};
                let req = api.user.update;
                await apiCall(req.url, req.method, payload, false);
                // reload user-profile
                // this.showInfoToast('Benutzerinformationen', 'Benutzerinformationen wurde erfolgreich aktualisiert.');
                await this._loadUserProfile(true);
            },

            goToProfile() {
                this.$router.push(`/experten/${this.expertNameMeta}/${this.id}/detail`);
            }
        },
    }
</script>




<style scoped>
    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>