<template>
    <div :class="smaller ? 'col-12 col-md-6 col-lg-3 mt-4' : 'col-12 col-md-6 col-lg-4 mb-4'">
        <div class="card h-100 form-rounded-left form-rounded-right" style="cursor:pointer;" @click="goToCourseDetail">
            <div
                v-if="courseMediaUrl"
                class="card-img-top form-rounded-top"
                :style="imgCssStyle"
            />

            <!-- <img v-if="courseMediaUrl" :src="courseMediaUrl" class="card-img-top form-rounded-top" alt="...">-->

            <i v-else class="fas fa-image card-img-top form-rounded-top text-center mt-5 mb-5" style="font-size: 192px; color: lightgray"></i>

            <div class="card-body d-flex flex-column">
                <div class="row course-date mb-2 clearfix w-100" style="margin: 0 auto;" v-if="course.date_start">
                    <div class="col-2 course-date-day font-size-course-date p-2 form-rounded-left-light text-center">{{ courseDateDow }}</div>
                    <div class="col-5 course-date-date font-size-course-date p-2 text-center">{{ course.date_start ? dateFormat(course.date_start, 'DD.MM.YYYY') : '---- -- --' }}</div>
                    <div class="col-5 course-date-type font-size-course-date p-2 form-rounded-right-light text-center">{{ courseType }}</div>
                </div>
                <div class="course-date mb-2 clearfix text-center" v-else>
                    <span class="d-block font-size-course-date py-2" style="border: 1px solid; border-radius: 0.35rem;">Termin auf Anfrage</span>
                </div>

                <span class="course-zip font-size-course-zip d-block mb-2 text-center" v-html="courseLocation" />

                <span class="course-name-of-expert font-size-course-name-of-expert mp-qks d-block mb-2">{{ expertName }}</span>
                <h5 class="d-block card-title mp-rbk mt-3 mb-2">{{ course.title }}</h5>
                <p class="course-preview-description font-size-course-preview-description mb-4 mp-qks">{{ course.desc_brief }}</p>

                <div class="mt-auto">
                    <!-- <router-link v-if="!editMode" :to="`/course/${course.id}/detail`" class="btn btn-mp-green-profile px-4 mp-font-weight-medium">Details</router-link> -->
                    <span v-if="!editMode" class="btn btn-mp-green-profile px-4 mp-font-weight-medium" @click.stop="goToCourseDetail">Details</span>
                    <template v-else>
                        <a class="btn btn-mp-green-profile px-4 mp-font-weight-medium" style="margin-right: 5px" @click.stop="doEdit">Bearbeiten</a>
                        <a class="btn btn-mp-green-profile px-4 mp-font-weight-medium bg-danger" @click.stop="doDelete">Löschen</a>
                    </template>
                </div>
            </div>
            <!-- like button -->
            <!--
            <div v-if="isAuth" class="expert-like d-flex flex-column justify-content-center text-center mp-black-expert-like-btn form-shadow">
                <i class="fas fa-heart mp-green" v-if="isFavoriteCourse(course.id)" style="cursor: pointer" @click="toggleFavorite"></i>
                <i class="far fa-heart" v-else style="cursor: pointer" @click="toggleFavorite"></i>
            </div>
            -->
            <!-- /like button -->
        </div>
    </div>
</template>




<script>
    import {mixinBasics} from "../mixins/basics";
    import {mapActions} from "vuex";
    import {apiCall} from "../helpers";
    import Configs from "../configs";

    const api = Configs.restApi.endpoints.map;


    export default {
        name: "CourseCard",

        mixins: [
            mixinBasics
        ],

        props: {
            course: {
                type: Object,
                required: true
            },

            editMode: {
                type: Boolean,
                required: false,
                default : false
            },

            smaller: {
                type: Boolean,
                required: false,
                default : false
            }
        },

        data() {
            return {
                keyPrefix: 'courseCard'
            };
        },

        computed: {
            courseType() {
                return this._courseTypes.find(ct => ct.id === this.course.course_type_id).title;
            },

            courseMediaUrl() {
                return this.course.media.length > 0 ? this.course.media[0].url : '';
            },

            courseDesc() {
                return this.course.desc.length > 90 ? this.course.desc.substr(0,90) + '...' : this.course.desc;
            },

            courseDateDow() {
                if (!this.course.date_start) {
                    return '---'
                }
                let d = new Date(this.course.date_start);
                return d.toLocaleDateString('de-DE', { weekday: 'short' }).toUpperCase();
            },

            courseLocation() {
                return this.course.is_online_only ? 'online' : (this.course.zip || this.course.city) ? `${this.course.zip} ${this.course.city}` : '&nbsp;';
            },


            expertName() {
                let expert = this.getExpertByUserId(this.course.user_id);
                let title = expert.title ?? '';
                let titleTrailing = expert.title_trailing ? `, ${expert.title_trailing}` : '';
                return `${title} ${expert.firstname} ${expert.lastname}${titleTrailing}`;
            },

            imgCssStyle() {
                return `
                    height: 250px;
                    background: url('${this.courseMediaUrl}');
                    max-height: 250px;
                    height: 250px;
                    background-position: top center;
                    background-size: cover;
                    background-repeat: no-repeat;
                `;
            }
        },

        methods: {
            ...mapActions('main', {
                _loadUserProfile: 'loadUserProfile',
            }),

            async toggleFavorite() {
                let favs = this.isFavoriteCourse(this.course.id) ? this.favRemove() : this.favAdd();
                await this.doUserFavoritesSave(favs);
            },

            favAdd() {
                let res = this.profile.favoriteCourses.map(e => e.id);
                res.push(this.course.id);
                return res;
            },

            favRemove() {
                return this.profile.favoriteCourses.filter(e => e.id !== this.course.id).map(e => e.id);
            },

            async doUserFavoritesSave(favs) {
                let payload = {favorite_courses: favs};
                let req = api.user.update;
                await apiCall(req.url, req.method, payload, false);
                await this._loadUserProfile(true);
            },

            doEdit() {
                this.$emit('edit');
            },

            doDelete() {
                this.$emit('delete');
            },

            goToCourseDetail() {
                this.$router.push(`/kurse/${this.course.title}/${this.course.id}/detail`);
            }
        }
    }
</script>




<style scoped>

</style>