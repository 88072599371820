<template>
    <div>
        <!-- filter -->
        <div class="row" v-if="showFilter">

            <div class="col-12">
                <div class="container">
                    <div class="row pb-3" v-if="searchEnabled">
                        <div class="col-12 col-lg-10 offset-lg-1 d-flex justify-content-center">
                            <div class="input-group">
                                <span class="border-0 input-group-text search form-rounded-left" id="basic-addon2"><i class="fa fa-search"></i></span>
                                <input class="form-control py-3 border-0" list="searchDataListOptions" id="searchDataList" placeholder="Suche z.B. &quot;Selbsterfahrung&quot;" v-model="filter.searchTerm">
                                <datalist id="searchDataListOptions">
                                    <option v-for="t in searchOptions" :value="t.title" :key="t.id">{{ t.title }}</option>
                                </datalist>
                                <button
                                    class="btn btn-outline-secondary form-rounded-right btn-search-green mp-qks mp-font-weight-medium font-size-search-button border-0"
                                    type="button" id="button-addon2"
                                    @click="doSearch"
                                >Suchen
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row pb-2">
                        <!-- list mode -->
                        <div class="col-12 col-lg-2 d-none d-md-block">
                            <span class="mp-qks font-size-filter-title mp-font-weight-medium d-block my-1">&nbsp;</span>
                            <template v-if="disableListModeSwitching">
                                <span
                                    class="bg-transparent text-black"
                                    style="display: block; border: 1px solid #212529; border-radius: 0.25rem; padding: .375rem 2.25rem .375rem .75rem;"
                                >{{currentListModeTitle}}</span>
                            </template>
                            <template v-else>
                            <select
                                class="form-select bg-transparent border-1 border-dark text-black"
                                aria-label="Angebot"
                                v-model="filter.listContent"
                                :aria-readonly="disableListModeSwitching"
                                :disabled="disableListModeSwitching"
                                :style="disableListModeSwitching ? 'cursor: not-allowed' : ''"
                            >
                                <option :value="listModeExperts">Experten</option>
                                <option :value="listModeCourses">Angebote</option>
                            </select>
                            </template>
                        </div>
                        <!-- list style -->
                        <div class="col-12 col-lg-2 d-none d-md-block">
                            <span class="mp-qks font-size-filter-title mp-font-weight-medium d-block my-1">Darstellung</span>
                            <select class="form-select bg-transparent border-1 border-dark text-black" aria-label="Ansicht" v-model="filter.listStyleTiles">
                                <option :value="true">Kacheln</option>
                                <option :value="false">Liste</option>
                            </select>
                        </div>
                        <!-- sort order -->
                        <div class="col-12 col-lg-2">
                            <span class="mp-qks font-size-filter-title mp-font-weight-medium d-block my-1">Sortieren nach
                                <i class="fas fa-info-circle mp-green" style="cursor: pointer" @click="showDlgSortInfo = true"></i>
                            </span>
                            <select class="form-select bg-transparent border-1 border-dark text-black" aria-label="Sortieren nach" v-model="filter.sortBy" @change="setOrderBy">
                                <option :value="sortBy.LEVEL" selected>Empfehlung</option>
                                <option :value="sortBy.NAME">Namen</option>
                                <option :value="sortBy.DISTANCE">Entfernung</option>
                            </select>
                        </div>
                        <!-- Category -->
                        <div class="col-12 col-lg-3">
                            <span class="mp-qks font-size-filter-title mp-font-weight-medium d-block my-1">Kategorie</span>
                            <select class="form-select bg-transparent border-1 border-dark text-black" aria-label="Kategorie" v-model="filter.categoryId" @change="filter.areaId = undefined">
                                <option selected :value="undefined">Kategorie wählen</option>
                                <option v-for="c in _categories" :value="c.id" :key="`${keyPrefix}_${c.id}_c`">{{ c.title }}</option>
                            </select>
                        </div>
                        <!-- Area -->
                        <div class="col-12 col-lg-3">
                            <span class="mp-qks font-size-filter-title mp-font-weight-medium d-block my-1">Fachgebiet</span>
                            <select class="form-select bg-transparent border-1 border-dark text-black" aria-label="Fachgebiet" v-model="filter.areaId">
                                <option selected :value="undefined">Fachgebiet wählen</option>
                                <template v-if="selectedCategory">
                                    <option v-for="a in selectedCategory.areas" :value="a.id" :key="`${keyPrefix}_${a.id}_s_c`">{{ a.title }}</option>
                                </template>
                            </select>
                        </div>
                        <!-- offer type -->
                        <!--
                        <div class="col-12 col-lg-2">
                            <span class="mp-qks font-size-filter-title mp-font-weight-medium d-block my-1">Art des Angebotes</span>
                            <select class="form-select bg-transparent border-1 border-dark text-black" aria-label="Art des Angebotes" v-model="filter.courseTypeId">
                                <option selected :value="undefined">Art wählen</option>
                                <option v-for="o in _courseTypes" :value="o.id" :key="`${keyPrefix}_${o.id}_s_o`">{{ o.title }}</option>
                            </select>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>
        <!-- result -->
        <div class="row">
            <div class="col-12">
                <div class="container">
                    <Experts v-if="!filter.listContent || filter.listContent === listModeExperts" />
                    <Courses v-else />
                </div>
            </div>
        </div>

        <!-- toasts -->
        <Toast v-if="showToast" :title="toastTitle" :msg="toastMsg" :background-color="toastBgColor" :delay="10000" @close="showToast = false" />

        <!--- dialog -->
        <info
            v-if="showDlgSortInfo"
            title="Sortierung"
            msg="Die von den Experten gebuchten Pakete sowie andere Faktoren können das Ranking in den Suchergebnissen beeinflussen."
            @close="showDlgSortInfo = false"
        />
    </div>
</template>




<script>
    import {mixinBasics} from "../mixins/basics";
    import {mapActions} from "vuex"
    import Toast from "../components/Toast";
    import Constants from "../constants";
    import Experts from "../components/Experts.vue";
    import Courses from "./Courses.vue";
    import Info from "./modals/Info.vue";

    const listMode = [
        {id: Constants.filterListMode.COURSES, title: 'Angebote'},
        {id: Constants.filterListMode.EXPERTS, title: 'Experten'},
    ];

    export default {
            name: "OfferFilter",

            components: {
                Info,
                Courses,
                Toast,
                Experts
            },

            mixins: [
                mixinBasics
            ],

            props: {
                showFilter: {
                    type: Boolean,
                    required: false,
                    default: true,
                },
                searchEnabled: {
                    type: Boolean,
                    required: false,
                    default: true,
                },
                resultLimit: {
                    type: Number,
                    required: false,
                    default: undefined,
                },
                showExperts: {
                    type: Boolean,
                    required: false,
                    default: undefined,
                },
                showCourses: {
                    type: Boolean,
                    required: false,
                    default: undefined,
                },
                disableListModeSwitching: {
                    type: Boolean,
                    required: false,
                    default: false,
                }
            },

            data() {
                return {
                    keyPrefix: 'exps',
                    isLoading: false,

                    // toast
                    toastMsg: '',
                    toastTitle: '',
                    toastBgColor: undefined,
                    showToast: false,
                    showDlgSortInfo: false,

                    gps: {
                        lat: undefined,
                        long: undefined
                    }
                };
            },

            watch: {
                'filter.categoryId': {
                    handler(newValue) {
                        this.$emit('filter-changed', {categoryId: newValue, areaId: this.areaId});
                    },
                    deep: true,
                    immediate: false
                },
                'filter.areaId': {
                    handler(newValue) {
                        this.$emit('filter-changed', {categoryId: this.categoryId, areaId: newValue});
                    },
                    deep: true,
                    immediate: false
                },
            },

            computed: {
                selectedCategory() {
                    return this.filter.categoryId ? this._categories.find(c => c.id === this.filter.categoryId) : undefined;
                },

                searchOptions() {
                    if (!this.filter.searchTerm) {
                        return this._tags;
                    }
                    let search = this.filter.searchTerm.toLowerCase();
                    return this._tags.filter(t => t.title.toLowerCase().indexOf(search) >= 0);
                },

                listModeExperts() {
                    return Constants.filterListMode.EXPERTS;
                },

                listModeCourses() {
                    return Constants.filterListMode.COURSES;
                },

                currentListModeTitle() {
                    return listMode.find(e => e.id === this.filter.listContent).title;
                },

                sortBy() {
                    return Constants.filterSortBy;
                },
            },

            methods: {
                ...mapActions('main', {
                    _loadExperts: 'loadExperts'
                }),

                async setOrderBy() {
                    if (this.filter.sortBy === Constants.filterSortBy.DISTANCE) {
                        navigator.geolocation.getCurrentPosition(this.gpsSuccess, this.gpsError)
                    }
                },

                async gpsSuccess(pos) {
                    let crd = pos.coords;
                    this.gps.lat = crd.latitude;
                    this.gps.long = crd.longitude;

                    this.isLoading = true;
                    await this._loadExperts({gps_lat: this.gps.lat, gps_long: this.gps.long});
                    this.isLoading = false;

                    console.log('Your current position is:');
                    console.log(`Latitude : ${crd.latitude}`);
                    console.log(`Longitude: ${crd.longitude}`);
                },

                gpsError(err) {
                    console.log(`ERROR(${err.code}): ${err.message}`);
                    this.showInfoToast('Positionsbestimmung', 'Positionsbestimmung nicht möglich, oder verweigert.', '#842029');
                    this.filter.sortBy = Constants.filterSortBy.NAME;
                },

                showInfoToast(title, msg, backBgColor = undefined) {
                    this.toastTitle = title;
                    this.toastMsg = msg;
                    this.toastBgColor = backBgColor;
                    this.showToast = true;
                },

                doSearch() {
                    console.log(`Searching for "${this.filter.searchTerm}"... pls wait ;)`)
                }
            },

            created() {
                this.filter.resultLimit = this.resultLimit;
                this.filter.listContent = this.showExperts ? Constants.filterListMode.EXPERTS : this.showCourses ? Constants.filterListMode.COURSES : this.filter.listContent;
            }
    }
</script>




<style scoped>

</style>