<template>
    <div class="row mt-3" style="cursor: pointer;" @click="goToCourseDetail">
        <div class="col-12 ps-4 pe-4">
            <div class="row">
                <div class="col-2 ps-0 pe-0 form-rounded-left" style="max-height: 168px; overflow: hidden;">
                    <img v-if="courseMediaUrl" :src="courseMediaUrl" class="mw-100 form-rounded-left" alt="..." />
                </div>

                <div class="col-3 bg-white pt-4 ps-4">
                    <div class="row">
                        <div class="col-12">
                            <span class="course-name-of-expert font-size-course-name-of-expert mp-qks d-block">{{ expertName }}</span>
                        </div>

                        <template v-if="course.date_start">
                            <div class="col-12 pt-3">
                                <span class="course-date-day font-size-course-date p-2 form-rounded-left-light">{{ courseDateDow }}</span>
                                <span class="course-date-date font-size-course-date p-2">{{ course.date_start ? dateFormat(course.date_start, 'DD.MM.YYYY') : '---- -- --' }}</span>
                                <span class="course-date-type font-size-course-date p-2 form-rounded-right-light">{{ courseType }}</span>
                            </div>
                            <div class="col-12 pt-3">
                                <span v-if="course.is_online_only" class="course-zip d-block">Online</span>
                                <span v-else class="course-zip d-block mb-2">{{ course.zip }} {{ course.city }}</span>
                            </div>
                        </template>

                        <template v-else>
                            <div class="col-12 pt-3">
                                <span class="font-size-course-date">Termin auf Anfrage</span>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="col-3 bg-white pt-4 ps-5">
                    <h5 class="mp-rbk font-size-course-preview-description">{{ course.title }}</h5>
                </div>

                <div class="col-4 bg-white pt-4 ps-5">
                    <h4 class="course-zip font-size-course-zip">{{ course.desc_brief }}</h4>
                </div>
            </div>
        </div>

    </div>
</template>




<script>
    import CourseCard from "./CourseCard.vue";

    const COURSE_DESC_MAX_LEN = 300;

    export default {
        name: "CourseCardHorizontal",
        extends: CourseCard,

        data() {
            return {
                keyPrefix: 'courseCardH'
            };
        },

        computed: {
            courseDesc() {
                return this.course.desc.length > COURSE_DESC_MAX_LEN ? this.course.desc.substr(0, COURSE_DESC_MAX_LEN) + '...' : this.course.desc;
            },

        }
    }
</script>




<style scoped>

</style>